import React from 'react'
import { Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

const CalcModal = () => (
  <div>
    <iframe src="https://calculator.redundancyclaim.co.uk/?id=2" title="Director Redundancy Calculator" width="100%" height="1200" scrolling="no" class="rcuk-calc-iframe"></iframe>
  </div>
)

export default CalcModal